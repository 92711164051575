import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RadioGroupButtons from 'components/RadioGroupButtons/RadioGroupButtons'
import { StyledRadioGroupContainer, StyledRadioGroupCol } from './AddEquipmentRadioGroup.styled'
import { insulatedWalls, guestToilet, surfaceMountedInstalationEFH } from './helper'
import { useTranslation } from 'react-i18next'
import {
  selectAddressFormData,
  setEquipmentDetailsChanged,
  setFormField,
} from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

function AddEquipmentRadioGroup() {
  const dispatch = useAppDispatch()
  const [validation, setValidationError] = useState(undefined)
  const addressFormData = useSelector(selectAddressFormData)
  const { t } = useTranslation()

  useEffect(() => {
    setValidationError({
      guestToilet: undefined,
      insulatedExteriorWalls: undefined,
      bathroom: undefined,
      surfaceMountedInstallation: undefined,
      residentialArea: undefined,
    })
  }, [])

  const onChangeGuestToilet = (guestToilet: string) => {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentMatrix.guestToilet', value: guestToilet })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeInsulatedWalls = (insulatedExteriorWalls: string) => {
    dispatch(
      setFormField({
        key: 'propertyDetails.equipmentMatrix.insulatedExteriorWalls',
        value: insulatedExteriorWalls,
      })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }
  const onChangeSurfaceInstalation = (surfaceMountedInstallation: string) => {
    dispatch(
      setFormField({
        key: 'propertyDetails.equipmentMatrix.surfaceMountedInstallation',
        value: surfaceMountedInstallation,
      })
    )
    dispatch(setEquipmentDetailsChanged(true))
  }

  return (
    <StyledRadioGroupContainer>
      <StyledRadioGroupCol>
        <RadioGroupButtons
          options={guestToilet}
          validationErrorCheckbox={!!validation?.['propertyDetails.equipmentMatrix.guestToilet']}
          label={t('newAddressModal.furnishingTab.separateGuestToilet')}
          onChange={(event) => onChangeGuestToilet(event.target.value)}
          defaultValue={addressFormData.addressData?.propertyDetails?.equipmentMatrix?.guestToilet}
        />
      </StyledRadioGroupCol>
      <StyledRadioGroupCol>
        <RadioGroupButtons
          options={insulatedWalls}
          validationErrorCheckbox={
            !!validation?.['propertyDetails.equipmentMatrix.insulatedExteriorWalls']
          }
          label={t('newAddressModal.furnishingTab.insulatedOuterWalls')}
          onChange={(event) => onChangeInsulatedWalls(event.target.value)}
          defaultValue={
            addressFormData.addressData?.propertyDetails?.equipmentMatrix?.insulatedExteriorWalls
          }
        />
      </StyledRadioGroupCol>
      {addressFormData.addressData?.propertyDetails?.category === 'EFH' ? (
        <StyledRadioGroupCol>
          <RadioGroupButtons
            options={surfaceMountedInstalationEFH}
            validationErrorCheckbox={
              !!validation?.['propertyDetails.equipmentMatrix.surfaceMountedInstallation']
            }
            label={t('newAddressModal.furnishingTab.linesOnPlaster')}
            onChange={(event) => onChangeSurfaceInstalation(event.target.value)}
            defaultValue={
              addressFormData.addressData?.propertyDetails?.equipmentMatrix
                ?.surfaceMountedInstallation
            }
          />
        </StyledRadioGroupCol>
      ) : null}
    </StyledRadioGroupContainer>
  )
}

export default AddEquipmentRadioGroup
