import React from 'react'
import { EquipmentLevel } from 'types/EquipmentLevel'
import { useTranslation } from 'react-i18next'
import { StyledLevelDescription, StyledSlider } from './AddEquipmentLevelSlider.styled'
import { useSelector } from 'react-redux'
import { selectAddressFormData, setFormField } from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

const EQUIPMENT_LEVEL_VALUE_MAP = {
  10: EquipmentLevel.EINFACH,
  20: EquipmentLevel.EINFACH_MITTEL,
  30: EquipmentLevel.MITTEL,
  40: EquipmentLevel.MITTEL_GEHOBEN,
  50: EquipmentLevel.GEHOBEN,
  60: EquipmentLevel.GEHOBEN_STARK_GEHOBEN,
  70: EquipmentLevel.STARK_GEHOBEN,
}

const getEquipmentLevel = (value: number) => {
  return EQUIPMENT_LEVEL_VALUE_MAP[value] || EquipmentLevel.MITTEL_GEHOBEN
}

const getEquipmentLevelValue = (equipmentLevel?: EquipmentLevel) => {
  return (
    Object.keys(EQUIPMENT_LEVEL_VALUE_MAP).filter(
      (key) => EQUIPMENT_LEVEL_VALUE_MAP[key] === equipmentLevel
    )[0] || 40
  )
}

const AddEquipmentLevelSlider = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const addressFormData = useSelector(selectAddressFormData)
  const marks = [
    {
      value: 10,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.EINFACH.toLowerCase()}`)}</p>,
    },
    {
      value: 20,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.EINFACH_MITTEL.toLowerCase()}`)}</p>,
    },
    {
      value: 30,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.MITTEL.toLowerCase()}`)}</p>,
    },
    {
      value: 40,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.MITTEL_GEHOBEN.toLowerCase()}`)}</p>,
    },
    {
      value: 50,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.GEHOBEN.toLowerCase()}`)}</p>,
    },
    {
      value: 60,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.GEHOBEN_STARK_GEHOBEN.toLowerCase()}`)}</p>,
    },
    {
      value: 70,
      label: <p>{t(`equipmentLevel.${EquipmentLevel.STARK_GEHOBEN.toLowerCase()}`)}</p>,
    },
  ]

  const handleChange = (_: Event, newValue: number) => {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentValue', value: getEquipmentLevel(newValue) })
    )
  }

  if (addressFormData.addressData?.propertyDetails?.equipmentValue === undefined) {
    dispatch(
      setFormField({ key: 'propertyDetails.equipmentValue', value: EquipmentLevel.MITTEL_GEHOBEN })
    )
  }

  return (
    <div style={{ marginTop: 100 }}>
      <StyledSlider
        marks={marks}
        min={5}
        max={75}
        step={null}
        track={false}
        defaultValue={getEquipmentLevelValue(
          addressFormData.addressData?.propertyDetails?.equipmentValue
        )}
        onChange={handleChange}
      />
      {addressFormData.addressData?.propertyDetails?.equipmentValue !== undefined && (
        <StyledLevelDescription>
          <b>
            {t(
              `equipmentLevel.${addressFormData.addressData?.propertyDetails?.equipmentValue.toLowerCase()}`
            )}
            :
          </b>{' '}
          {t(
            `equipmentLevel.${addressFormData.addressData?.propertyDetails?.equipmentValue.toLowerCase()}.description`
          )}
        </StyledLevelDescription>
      )}
    </div>
  )
}

export default AddEquipmentLevelSlider
