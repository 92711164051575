export const requiredFields = {
  page1: ['street', 'lat', 'lng', 'houseNumber', 'zip', 'nation'],
  page2ETW: [
    'propertyDetails.category',
    'propertyDetails.construction',
    'propertyDetails.constructionYear',
    'propertyDetails.livingArea',
  ],
  page2EFH: [
    'propertyDetails.category',
    'propertyDetails.construction',
    'propertyDetails.constructionYear',
    'propertyDetails.livingArea',
    'propertyDetails.plotArea',
  ],
  page2MFH: [
    'propertyDetails.category',
    'propertyDetails.construction',
    'propertyDetails.constructionYear',
    'propertyDetails.livingArea',
    'propertyDetails.plotArea',
  ],
  page2Index: [
    'indexDetails.includeContactDetails',
    'indexDetails.reportType',
    'indexDetails.periodType',
  ],
}
