import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import RadioGroupButtons from 'components/RadioGroupButtons/RadioGroupButtons'
import { StyledObjectType } from './AddObjectType.styled'
import { checkValidationError } from 'common/utils/CheckValidationError'
import { useTranslation } from 'react-i18next'
import {
  includeContactDetailsSelect,
  periodTypeSelect,
  reportTypeSelect,
} from '../AddHouseParameters/helper'
import {
  selectAddressFormData,
  setEquipmentDetailsChanged,
  setFormField,
} from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

const AddObjectType = () => {
  const dispatch = useAppDispatch()
  const addressFormData = useSelector(selectAddressFormData)
  const { t } = useTranslation()
  const isDE = addressFormData.addressData?.nation === 'DE'
  const isIndex = addressFormData.productName === 'index'
  const reportTypeSelectArray = [...reportTypeSelect]
  const periodTypeSelectArray = [...periodTypeSelect]

  const handleChangeObjectType = useCallback(
    (value: string) => {
      dispatch(setFormField({ key: 'propertyDetails.category', value }))
      dispatch(setEquipmentDetailsChanged(true))
    },
    [dispatch]
  )

  const handleChangeReportType = (value: string) => {
    dispatch(setFormField({ key: 'indexDetails.reportType', value }))
  }

  const handleChangePeriodType = (value: string) => {
    dispatch(setFormField({ key: 'indexDetails.periodType', value }))
  }

  const handleChangeIncludeContactDetails = (value: string) => {
    dispatch(setFormField({ key: 'indexDetails.includeContactDetails', value }))
  }

  const objectTypes = [
    {
      key: 'EFH',
      value: (
        <label>
          {t(`newAddressModal.objectDataTab.objectTypes.detachedHouse`)} <p>EFH</p>
        </label>
      ),
    },
    {
      key: 'ETW',
      value: (
        <label>
          {t('newAddressModal.objectDataTab.objectTypes.condominium')} <p>ETW</p>
        </label>
      ),
    },
    {
      key: 'MFH',
      value: (
        <label>
          {t('newAddressModal.objectDataTab.objectTypes.apartment')} <p>MFH</p>
        </label>
      ),
    },
  ].filter(Boolean)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isIndex) return
    if (
      !addressFormData.addressData?.indexDetails?.reportType &&
      !addressFormData.addressData?.indexDetails?.includeContactDetails
    ) {
      dispatch(setFormField({ key: 'indexDetails.reportType', value: 'VALUATION' }))
      dispatch(setFormField({ key: 'indexDetails.includeContactDetails', value: false }))
    }
    if (!addressFormData.addressData?.indexDetails?.periodType) {
      dispatch(setFormField({ key: 'indexDetails.periodType', value: 'YEAR_1' }))
    }
  }, [
    dispatch,
    isIndex,
    addressFormData.addressData?.indexDetails?.reportType,
    addressFormData.addressData?.indexDetails?.periodType,
    addressFormData.addressData?.indexDetails?.includeContactDetails,
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!addressFormData.addressData?.propertyDetails?.category) {
      handleChangeObjectType('EFH')
    }
  }, [addressFormData.addressData, handleChangeObjectType])

  return (
    (objectTypes.length && (
      <>
        <StyledObjectType>
          <RadioGroupButtons
            validationErrorCheckbox={checkValidationError(
              addressFormData.validationErrors,
              'propertyDetails.category'
            )}
            options={isDE ? objectTypes : objectTypes.filter((item) => item.key !== 'MFH')}
            label={t('newAddressModal.objectDataTab.objectType')}
            onChangeProps={handleChangeObjectType}
            value={addressFormData.addressData?.propertyDetails?.category}
          />
        </StyledObjectType>
        {isIndex && (
          <>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'indexDetails.reportType'
                )}
                options={reportTypeSelectArray}
                label={t('newAddressModal.objectDataTab.reportType')}
                onChangeProps={handleChangeReportType}
                value={`${addressFormData.addressData?.indexDetails?.reportType}`}
              />
            </StyledObjectType>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'indexDetails.periodType'
                )}
                options={periodTypeSelectArray}
                label={t('newAddressModal.objectDataTab.periodType')}
                onChangeProps={handleChangePeriodType}
                value={`${addressFormData.addressData?.indexDetails?.periodType}`}
              />
            </StyledObjectType>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'indexDetails.includeContactDetails'
                )}
                options={includeContactDetailsSelect}
                label={t('newAddressModal.objectDataTab.includeContactDetails')}
                onChangeProps={handleChangeIncludeContactDetails}
                value={`${addressFormData.addressData?.indexDetails?.includeContactDetails}`}
              />
            </StyledObjectType>
          </>
        )}
      </>
    )) ||
    null
  )
}

export default AddObjectType
