import { CoinHistoryType } from 'types/ApiResponse'
import dayjs from 'dayjs'

const generateCSV = (data, fileName) => {
  const link = document.createElement('a')
  const blob = new Blob(['\uFEFF' + data], { type: 'text/csv;charset=UTF-8;' })
  const url = URL.createObjectURL(blob)

  link.setAttribute('href', url)
  link.setAttribute('download', `${fileName}.csv`)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getValue = (value) => {
  if (value == null) return ''
  return value
}

export const generateLeadCSV = (address) => {
  const headers = [
    'date',
    'nation',
    'zip',
    'town',
    'street',
    'houseNumber',
    'lat',
    'lng',
    'category',
    'construction',
    'constructionYear',
    'livingArea',
    'plotArea',
    'garages',
    'elevator',
    'equipment',
    'value',
    'rangeMin',
    'rangeMax',
    'name',
    'surname',
    'email',
    'phone',
  ]
  const csv = []

  csv.push(headers.join(';'))
  csv.push(
    `${getValue(address.leadDetails.date)};${getValue(address.nation)};${getValue(
      address.zip
    )};${getValue(address.town)};${getValue(address.street)};${getValue(
      address.houseNumber
    )};${getValue(address.lat)};${getValue(address.lng)};${getValue(
      address.propertyDetails.category
    )};${getValue(address.propertyDetails.construction)};${getValue(
      address.propertyDetails.constructionYear
    )};${getValue(address.propertyDetails.livingArea)};${getValue(
      address.propertyDetails.plotArea
    )};${getValue(address.propertyDetails.garages)};${getValue(
      address.propertyDetails.elevator
    )};${getValue(address.propertyDetails.equipmentValue)};${getValue(
      address.leadDetails.valuationValue
    )};${getValue(address.leadDetails.valuationMetaRangeMin)};${getValue(
      address.leadDetails.valuationMetaRangeMax
    )};${getValue(address.leadDetails.name)};${getValue(address.leadDetails.surname)};${getValue(
      address.leadDetails.email
    )};${getValue(address.leadDetails.phone)}`
  )

  generateCSV(
    csv.join('\r\n'),
    `${address.street}-${address.zip}-${address.town}-${address.nation}`
  )
}

export const generateIndicesCSV = (indicesData, zip, town) => {
  const csv = []
  const headers = [
    'year',
    'month',
    'quarter',
    'index',
    'scale',
    'region',
    'object type',
    'type',
    'date',
  ]

  csv.push(headers.join(';'))
  for (const indicesRow of indicesData.values) {
    const indexValue = indicesRow.index.toLocaleString('de-DE', {
      useGrouping: false,
    })
    csv.push(
      `${indicesRow.year};${indicesRow.month};${indicesRow.quarter};${indexValue};${
        indicesRow.scale
      };${indicesRow.region};${indicesData.objectCategory};${indicesData.type.toLowerCase()};${
        indicesData.date
      }`
    )
  }

  generateCSV(csv.join('\r\n'), `indices_${zip}-${town}`)
}

const getCoinHistoryValue = (value, translatedValue = null) => {
  if (value == null) return ''
  if (translatedValue == null) return value
  return translatedValue
}

const getCoinHistoryDate = (value) => {
  const parsed = dayjs(value, 'YYYY-MM-DD HH:mm:ss.SSS')
  return parsed.format('YYYY-MM-DD HH:mm:ss')
}

export const generateCoinsHistoryCSV = (coinsHistoryData: CoinHistoryType[], t, date: string) => {
  const csv = []
  const headers = [
    t('coinHistoryList.headers.date'),
    t('coinHistoryList.headers.type'),
    t('coinHistoryList.headers.product'),
    t('coinHistoryList.headers.amount'),
  ]

  csv.push(headers.join(';'))
  for (const coinHistoryElement of coinsHistoryData) {
    csv.push(
      `${getCoinHistoryDate(coinHistoryElement.transactionDate)};${getCoinHistoryValue(
        coinHistoryElement.origin,
        t(`coinHistoryList.type.${coinHistoryElement.origin.toLowerCase()}`)
      )};${getCoinHistoryValue(
        coinHistoryElement.product,
        t(
          `coinHistoryList.product.${coinHistoryElement.product?.toLowerCase()}`,
          coinHistoryElement.product
        )
      )};${getCoinHistoryValue(coinHistoryElement.delta)}`
    )
  }
  generateCSV(csv.join('\r\n'), `coins-history_${date}`)
}
