import React, { useEffect, useState } from 'react'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import RadioGroupButtons from 'components/RadioGroupButtons/RadioGroupButtons'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from 'components/Input/Input'
import { useSelector } from 'react-redux'
import {
  StyledHouseParametersCol,
  StyledHouseParametersRowContainer,
  StyledHouseParametersColConstruction,
  StyledHouseParametersColConstructionYear,
  StyledHouseParametersColLivingArea,
  StyledHouseParametersColElevator,
  StyledHouseParametersColGarage,
  StyledHouseParametersColPlotArea,
} from './AddHouseParameters.styled'
import { checkValidationError } from 'common/utils/CheckValidationError'
import { checkReqField } from '../../util'
import { useDebouncedCallback } from 'use-debounce'
import {
  garageSelect,
  parkingSpace,
  elevatorSelect,
  hausTypeEFHandMFH,
  hausTypeETW,
  hausTypeETW_AT,
} from './helper'
import { useTranslation } from 'react-i18next'
import {
  removeFormField,
  selectAddressFormData,
  setEquipmentDetailsChanged,
  setFormField,
} from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

export interface AddHouseParametersProps {
  nextButton: React.MutableRefObject<undefined>
  onlyMandatoryFields: boolean
}

const AddHouseParameters = (props: AddHouseParametersProps) => {
  const { nextButton, onlyMandatoryFields } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [validation, setValidationError] = useState(undefined)
  const addressFormData = useSelector(selectAddressFormData)
  const isDE = addressFormData.addressData?.nation === 'DE'
  const category = addressFormData.addressData?.propertyDetails?.category
  const livingArea = addressFormData.addressData?.propertyDetails?.livingArea

  useEffect(() => {
    setValidationError({
      construction: undefined,
      constructionYear: undefined,
      livingArea: undefined,
      plotArea: undefined,
    })
  }, [])

  useEffect(() => {
    checkReqField(addressFormData.addressData, setValidationError, addressFormData.validationErrors)
  }, [addressFormData.addressData, addressFormData.validationErrors])

  const validateNumericField = useDebouncedCallback(
    (currentValue, min, max, key, validateOnFocusOut?) => {
      const numericValue = parseInt(currentValue)
      const numericMin = parseInt(min)
      const numericMax = parseInt(max)
      if (
        (key === 'propertyDetails.constructionYear' &&
          !validateOnFocusOut &&
          currentValue?.length < 4) ||
        (numericValue >= numericMin && numericValue <= numericMax)
      ) {
        dispatch(setFormField({ key, value: numericValue }))
      } else if (numericValue > numericMax) {
        dispatch(setFormField({ key, value: numericMax }))
      } else {
        dispatch(setFormField({ key, value: numericMin }))
      }
    },
    500
  )

  useEffect(() => {
    if (livingArea !== undefined) {
      validateNumericField(
        livingArea,
        1,
        category === 'MFH' ? 1000 : 500,
        'propertyDetails.livingArea'
      )
    }
  }, [category, livingArea, validateNumericField])

  const onChangeHouseType = (construction: string) => {
    dispatch(setFormField({ key: 'propertyDetails.construction', value: construction }))
    dispatch(setEquipmentDetailsChanged(true))
  }

  const onChangeBuildYear = (constructionYear: string, validateOnFocusOut?: boolean) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (constructionYear.length === 0) {
      dispatch(removeFormField('propertyDetails.constructionYear'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.constructionYear', value: constructionYear }))
    validateNumericField(
      constructionYear,
      1800,
      new Date().getFullYear(),
      'propertyDetails.constructionYear',
      validateOnFocusOut
    )
  }

  const onChangeLivingArea = (livingArea: string) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (livingArea.length === 0) {
      dispatch(removeFormField('propertyDetails.livingArea'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.livingArea', value: livingArea }))
    validateNumericField(
      livingArea,
      1,
      category === 'MFH' ? 1000 : 500,
      'propertyDetails.livingArea'
    )
  }

  const onChangePlotArea = (plotArea: string) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (plotArea.length === 0) {
      dispatch(removeFormField('propertyDetails.plotArea'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.plotArea', value: plotArea }))
    validateNumericField(plotArea, 1, 5000, 'propertyDetails.plotArea')
  }

  const onChangeGarages = (garages: boolean) => {
    dispatch(setEquipmentDetailsChanged(true))
    dispatch(setFormField({ key: 'propertyDetails.garages', value: garages }))
  }

  const onChangeRooms = (rooms: string) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (rooms.length === 0) {
      dispatch(removeFormField('propertyDetails.rooms'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.rooms', value: rooms }))
    validateNumericField(rooms, 1, 100, 'propertyDetails.rooms')
  }

  const onChangeNumberOfFloors = (numberOfFloors: string) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (numberOfFloors.length === 0) {
      dispatch(removeFormField('propertyDetails.numberOfFloors'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.numberOfFloors', value: numberOfFloors }))
    validateNumericField(numberOfFloors, 1, 1000, 'propertyDetails.numberOfFloors')
  }

  const onChangeFloors = (floor: string) => {
    dispatch(setEquipmentDetailsChanged(true))
    if (floor.length === 0) {
      dispatch(removeFormField('propertyDetails.floorNumber'))
      return
    }
    dispatch(setFormField({ key: 'propertyDetails.floorNumber', value: floor }))
    validateNumericField(floor, 0, 100, 'propertyDetails.floorNumber')
  }

  const onChangeParkingPlace = (outdoorParkingSpace: boolean) => {
    dispatch(setEquipmentDetailsChanged(true))
    dispatch(
      setFormField({ key: 'propertyDetails.outdoorParkingSpace', value: outdoorParkingSpace })
    )
  }

  const onChangeElevator = (elevator: boolean) => {
    dispatch(setEquipmentDetailsChanged(true))
    dispatch(setFormField({ key: 'propertyDetails.elevator', value: elevator }))
  }

  const handleInputCommaValidation = (event) => {
    if (event.charCode === 44) {
      event.preventDefault()
      return false
    }
  }

  const validationMultiSelect = (value, options) => {
    if (!options) {
      return value
    }
    const matchingOption = options.find((option) => option.value === value)
    if (value && !matchingOption) {
      dispatch(setEquipmentDetailsChanged(true))
      dispatch(removeFormField('propertyDetails.construction'))
    }
  }

  return (
    <StyledHouseParametersRowContainer>
      <StyledHouseParametersCol>
        <StyledHouseParametersColConstruction
          validationError={!!validation?.['propertyDetails.construction']}
        >
          <MultiSelect
            value={addressFormData.addressData?.propertyDetails?.construction}
            options={category === 'ETW' ? (isDE ? hausTypeETW : hausTypeETW_AT) : hausTypeEFHandMFH}
            label={`${t('newAddressModal.objectDataTab.houseType')}*`}
            onChange={(event) => onChangeHouseType(event.target.value)}
            validation={validationMultiSelect}
          />
        </StyledHouseParametersColConstruction>
      </StyledHouseParametersCol>

      <StyledHouseParametersCol>
        <StyledHouseParametersColConstructionYear
          validationError={!!validation?.['propertyDetails.constructionYear']}
        >
          <Input
            value={addressFormData.addressData?.propertyDetails?.constructionYear}
            onChange={(event) => onChangeBuildYear(event.target.value)}
            onBlur={(event) => onChangeBuildYear(event.target.value, true)}
            onKeyPress={(event) => handleInputCommaValidation(event)}
            label={`${t('newAddressModal.objectDataTab.buildYear')}*`}
            helperText={`1800 – ${t('common.today')}`}
            nextButton={nextButton}
            defaultValue={addressFormData.addressData?.propertyDetails?.constructionYear}
          />
        </StyledHouseParametersColConstructionYear>
      </StyledHouseParametersCol>
      <StyledHouseParametersCol>
        <StyledHouseParametersColLivingArea
          validationError={!!validation?.['propertyDetails.livingArea']}
        >
          <Input
            value={addressFormData.addressData?.propertyDetails?.livingArea}
            onChange={(event) => onChangeLivingArea(event.target.value)}
            defaultValue={addressFormData.addressData?.propertyDetails?.livingArea}
            label={`${t('newAddressModal.objectDataTab.livingArea')}*`}
            nextButton={nextButton}
            helperText={`max. ${category === 'MFH' ? '1000' : '500'}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: '#92969a' }}>
                  m2
                </InputAdornment>
              ),
            }}
          />
        </StyledHouseParametersColLivingArea>
      </StyledHouseParametersCol>
      {category === 'ETW' ? null : (
        <StyledHouseParametersCol>
          <StyledHouseParametersColPlotArea
            validationError={!!validation?.['propertyDetails.plotArea']}
          >
            <Input
              value={addressFormData.addressData?.propertyDetails?.plotArea}
              onChange={(event) => onChangePlotArea(event.target.value)}
              defaultValue={addressFormData.addressData?.propertyDetails?.plotArea}
              label={`${t('newAddressModal.objectDataTab.plotArea')}*`}
              helperText="max. 5000"
              nextButton={nextButton}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: '#92969a' }}>
                    m2
                  </InputAdornment>
                ),
              }}
            />
          </StyledHouseParametersColPlotArea>
        </StyledHouseParametersCol>
      )}
      {!onlyMandatoryFields && category !== 'MFH' && (
        <StyledHouseParametersCol>
          <Input
            value={addressFormData.addressData?.propertyDetails?.rooms}
            onChange={(event) => onChangeRooms(event.target.value)}
            defaultValue={addressFormData.addressData?.propertyDetails?.rooms}
            label={`${t('newAddressModal.objectDataTab.rooms')}`}
            nextButton={nextButton}
            helperText="max. 100"
          />
        </StyledHouseParametersCol>
      )}
      {!onlyMandatoryFields && category !== 'ETW' && (
        <StyledHouseParametersCol>
          <Input
            value={addressFormData.addressData?.propertyDetails?.numberOfFloors}
            onChange={(event) => onChangeNumberOfFloors(event.target.value)}
            onKeyPress={(event) => handleInputCommaValidation(event)}
            defaultValue={addressFormData.addressData?.propertyDetails?.numberOfFloors}
            nextButton={nextButton}
            label={`${t('newAddressModal.objectDataTab.numberOfFloors')}`}
            helperText="max. 1000"
          />
        </StyledHouseParametersCol>
      )}
      {!onlyMandatoryFields && category === 'ETW' && (
        <StyledHouseParametersCol>
          <Input
            value={addressFormData.addressData?.propertyDetails?.floorNumber}
            onChange={(event) => onChangeFloors(event.target.value)}
            onKeyPress={(event) => handleInputCommaValidation(event)}
            defaultValue={addressFormData.addressData?.propertyDetails?.floorNumber}
            nextButton={nextButton}
            label={`${t('newAddressModal.objectDataTab.floor')}`}
            helperText="max. 100"
          />
        </StyledHouseParametersCol>
      )}
      {!onlyMandatoryFields && category === 'ETW' ? (
        <StyledHouseParametersCol>
          <StyledHouseParametersColElevator
            validationError={checkValidationError(
              addressFormData.validationErrors,
              'propertyDetails.elevator'
            )}
          >
            <RadioGroupButtons
              options={elevatorSelect}
              label={t('newAddressModal.objectDataTab.elevator')}
              onChange={(event) => onChangeElevator(event.target.value)}
              defaultValue={`${addressFormData.addressData?.propertyDetails?.elevator}`}
            />
          </StyledHouseParametersColElevator>
        </StyledHouseParametersCol>
      ) : null}
      {!onlyMandatoryFields && (
        <>
          <StyledHouseParametersCol>
            <StyledHouseParametersColGarage
              validationError={checkValidationError(
                addressFormData.validationErrors,
                'propertyDetails.garages'
              )}
            >
              <RadioGroupButtons
                options={garageSelect}
                label={t('newAddressModal.objectDataTab.garage')}
                onChange={(event) => onChangeGarages(event.target.value)}
                defaultValue={`${addressFormData.addressData?.propertyDetails?.garages}`}
              />
            </StyledHouseParametersColGarage>
          </StyledHouseParametersCol>
          <StyledHouseParametersCol>
            <RadioGroupButtons
              options={parkingSpace}
              label={t('newAddressModal.objectDataTab.parkingPlace')}
              onChange={(event) => onChangeParkingPlace(event.target.value)}
              defaultValue={`${addressFormData.addressData?.propertyDetails?.outdoorParkingSpace}`}
            />
          </StyledHouseParametersCol>
        </>
      )}
    </StyledHouseParametersRowContainer>
  )
}

export default AddHouseParameters
